<template>
  <div>
    <v-skeleton-loader
      v-show="isLoading"
      type="table"
    />

    <div v-show="!isLoading">
      <v-card class="max-h-content-container app-email h-full position-relative overflow-hidden text-sm">
        <div class="email-content-area h-full position-relative">
          <div class="d-flex align-center px-4 py-2">
            <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectAllOrderCheckbox"
              :indeterminate="isSelectAllOrderCheckboxIndeterminate"
              :disabled="!orders.length"
              :ripple="false"
              @change="selectAllCheckboxUpdate"
            ></v-checkbox>

            <v-btn
              v-show="selectedOrders.length > 0"
              icon
              small
              color="success"
              class="ml-1"
              :loading="isSubmitting"
              :ripple="false"
              @click="updateFlag(true)"
            >
              <v-icon>
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
            </v-btn>

            <v-spacer />

            <v-slider
              v-model="isDeliveredStatusSlider"
              :max="2"
              :color="isDeliveredStatusFilter.color"
              step="1"
              ticks="always"
              tick-size="4"
              hide-details
            />
          </div>

          <div
            class="ps-emails-list"
          >
            <v-card v-intersect="onIntersect">
            </v-card>

            <v-hover
              v-for="order in orders"
              v-slot="{ hover: isHovered }"
              :key="order.key"
            >
              <div
                :class="[{'hovered elevation-3': isHovered}, { 'email-read': order.isDelivered }]"
                class="email-item pa-4 d-flex align-center cursor-pointer"
              >
                <v-checkbox
                  class="d-flex mt-0 pt-0 mr-2"
                  hide-details
                  :input-value="selectedOrders.includes(Number(order.id))"
                  :ripple="false"
                  @click.stop
                  @change="toggleSelectedOrder(Number(order.id))"
                />

                <v-timeline
                  dense
                  class="timeline-custom-dense timeline-custom-dots"
                  style="width: 100%"
                >
                  <v-timeline-item
                    small
                    :color="order.isDelivered ? 'success' : 'warning'"
                  >
                    <div class="d-flex align-center justify-space-between">
                      <p class="text-sm pa-0 ma-0">
                        {{ order.time }}
                      </p>
                      <v-spacer />
                      <div class="d-flex flex-column align-end pr-2">
                        <p class="pa-0 ma-0 text-sm">
                          {{ order.tableName }}
                        </p>
                        <p class="text-xs pa-0 ma-0 secondary--text">
                          <span v-if="order.recipientName">
                            {{ order.recipientName }}
                          </span>
                          <span v-else>
                            ゲスト
                          </span>
                        </p>
                      </div>
                    </div>

                    <div
                      class="mt-4"
                    >
                      <span class="text--primary text-sm">
                        {{ order.vendibleName }}
                      </span>
                      <span
                        v-if="Number(order.quantity > 1)"
                        class="ml-2"
                      >
                        × {{ order.quantity }}
                      </span>
                      <v-chip-group
                        v-if="order.tagNames.length > 0"
                        column
                        small-chips
                        class="mt-1"
                      >
                        <v-chip
                          v-for="tagName in order.tagNames"
                          :key="`order${order.id}-${tagName}`"
                          x-small
                        >
                          {{ tagName }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
            </v-hover>

            <div
              class="d-none text-center text--primary font-weight-medium"
              :class="{'d-block': !orders.length}"
            >
              <p class="my-4">
                データがありません
              </p>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import {
  mdiCheckboxMarkedCircleOutline,
  mdiTransferRight,
} from '@mdi/js'
import {
  map,
  findIndex,
  chain,
  mapValues,
  orderBy,
  groupBy,
  values,
  flatMap,
  find,
  each,
} from 'lodash'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import useDateFormat from '@/views/composable/useDateFormat'

export default {
  setup() {
    const vm = getCurrentInstance().proxy

    const { hhmm } = useDateFormat()

    const isLoading = ref(false)
    const isSubmitting = ref(false)
    const isIntersecting = ref(false)
    const vendingHistories = ref([])
    const selectedOrders = ref([])
    const isDeliveredStatusSlider = ref(0)

    const isDeliveredStatusFilter = computed(() => {
      return [
        { color: 'secondary', value: 'all' },
        { color: 'warning', value: false },
        { color: 'success', value: true },
      ][isDeliveredStatusSlider.value]
    })

    // NOTE: - アイテムA 数量3 卓１みたいなデータを表示するため
    const orders = computed(() => {
      // NOTE: useReceiptPrinterDisplay > regulateVendingHistoriesとほ似てるけどとんど違う
      // こっちは時間(time)や卓(tableId)ごとでグループ分けしないといけない。
      // レシートはすべてまとまってればいいのでtimeとか考慮したくない(注文発生時間が違えど合計個数を合算したい)
      // 一方、オーダー一覧画面では時間ごとにどの卓でどのくらいの量の注文があったのか見たい(基本的にキッチンで使うため)
      return chain(vendingHistories.value)
        .orderBy('attributes.createdAt', ['desc'])
        .groupBy(vendingHistory => {
          const { vendibleName, isDelivered, createdAt } = vendingHistory.attributes
          const { tableId } = vendingHistory.meta
          const tagNames = map(vendingHistory.attributes.vendibleTags.data, 'attributes.name').join('')
          const time = hhmm(createdAt)
          const recipientIds = map(vendingHistory.attributes.vendibleRecipients.data, 'attributes.userId').join(',')

          return `${vendibleName}${tableId}${recipientIds}${tagNames}${time}${isDelivered}`
        })
        .mapValues((vendingHistoriesGrouped, key) => {
          const target = vendingHistoriesGrouped[0]
          const { vendibleName, isDelivered, createdAt } = target.attributes
          const { tableId, tableName } = target.meta
          const tagNames = map(target.attributes.vendibleTags.data, 'attributes.name')
          const time = hhmm(createdAt)
          const recipientName = map(target.attributes.vendibleRecipients.data, 'attributes.recipient.data.attributes.name').join(', ') || null
          const quantity = vendingHistoriesGrouped.length

          return {
            key,
            id: target.id,
            vendibleName,
            isDelivered,
            time,
            tagNames,
            tableId,
            tableName,
            recipientName,
            quantity,
            actualData: vendingHistoriesGrouped,
          }
        })
        .values()
        .filter(order => {
          const isDelivered = isDeliveredStatusFilter.value?.value
          if (isDelivered === 'all') return true

          return order.isDelivered === isDelivered
        })
        .value()
    })

    const selectAllOrderCheckbox = computed(
      () => orders.value.length > 0 && orders.value.length === selectedOrders.value.length,
    )
    const isSelectAllOrderCheckboxIndeterminate = computed(
      () => selectedOrders.value.length > 0 && orders.value.length !== selectedOrders.value.length,
    )

    const getVendingHistories = async () => {
      isLoading.value = true

      const res = await VendingHistoryApi.getVendingHistories({
        checkedOut: false,
        beforeCheckOut: true,
        isToday: true,
      })

      if (res?.data?.data) {
        vendingHistories.value = res.data.data
      }

      isLoading.value = false
    }

    const updateFlag = async (isDelivered = true) => {
      isSubmitting.value = true

      const ids = flatMap(selectedOrders.value, orderId => {
        const order = find(orders.value, o => Number(o.id) === Number(orderId))

        return map(order.actualData, 'id')
      })

      const res = await VendingHistoryApi.updateAllVendingHistories({
        ids,
        attributes: {
          isDelivered,
        },
      })

      if (res.data) {
        each(res.data.vendingHistories.data, data => {
          const vendingHistoryIdx = findIndex(vendingHistories.value, o => Number(o.id) === Number(data.id))
          if (vendingHistoryIdx === -1) return

          vendingHistories.value.splice(vendingHistoryIdx, 1, data)
        })

        vm.$toast.success('フラグを更新しました')
      }

      isSubmitting.value = false
    }

    const selectAllCheckboxUpdate = val => {
      selectedOrders.value = val ? map(orders.value, o => Number(o.id)) : []
    }
    const toggleSelectedOrder = orderId => {
      const orderIdx = findIndex(selectedOrders.value, id => Number(id) === Number(orderId))

      if (orderIdx === -1) selectedOrders.value.push(Number(orderId))
      else selectedOrders.value.splice(orderIdx, 1)
    }

    const onIntersect = (entries, _observer) => {
      isIntersecting.value = entries[0].isIntersecting
    }

    getVendingHistories()

    return {
      // data
      isLoading,
      isSubmitting,
      vendingHistories,
      selectedOrders,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      isDeliveredStatusSlider,
      isIntersecting,

      // computed
      isDeliveredStatusFilter,
      orders,
      selectAllOrderCheckbox,
      isSelectAllOrderCheckboxIndeterminate,

      // methods
      hhmm,
      getVendingHistories,
      selectAllCheckboxUpdate,
      toggleSelectedOrder,
      updateFlag,
      onIntersect,

      icons: {
        mdiCheckboxMarkedCircleOutline,
        mdiTransferRight,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins';

.app-email {
  // Style search input
  .email-search-input {
    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }
  }
}

@include theme(email-search-input) using ($material) {
  .v-input__slot {
    input {
      caret-color: map-deep-get($material, 'text', 'secondary') !important;
    }
  }
}

.ps-emails-list {
  height: calc(100% - 40px - 2px);
  overflow-y: scroll;

  .email-item {
    &.hovered {
      transform: translateY(-2px);
      transition: all 0.2s;
      z-index: 1;
    }
  }
}
.ps-emails-list::-webkit-scrollbar {
  display: none;
}
.ps-emails-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@include theme--child(ps-emails-list) using ($material) {
  .email-item {
    &:not(:last-child) {
      border-bottom: 1px solid map-deep-get($material, 'dividers');
    }
    &.email-read {
      background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
    }
  }
}
</style>
